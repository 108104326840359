import React from 'react';
import { navigate } from 'gatsby-link';
import Recaptcha from 'react-google-recaptcha';

import Layout from '../components/Layout';
import SEO from '../components/Other/seo';

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
}

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleRecaptcha = value => {
        this.setState({ 'g-recaptcha-response': value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch('/?no-cache=1', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state
            })
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error));
    };

    render = () => {
        return (
            <Layout>
                <SEO title="Florin Pop - Contact me" />
                <div className="center-container">
                    <div className="row middle-xs">
                        <header className="col-xs-12 text-center">
                            <h6>Have a new project in mind?</h6>
                            <h1>Come and say hi or just drop a line</h1>
                        </header>
                        <div className="col-xs-12">
                            <div className="row">
                                {/* <div className="col-sm-3 col-xs-12">
                                    <div className="contact-info">
                                        <a href="mailto:popflorin1705@yahoo.com">
                                            <i className="fa fa-envelope" />
                                            <strong>E-mail</strong>
                                        </a>
                                        <p>popflorin1705@yahoo.com</p>
                                    </div>
                                    <div className="contact-info">
                                        <i className="fa fa-map-marker" />
                                        <strong>Address</strong> <br />
                                        <p>Targu-Mures, Romania</p>
                                    </div>
                                    <div className="contact-info">
                                        <a href="skype:florinpop1705">
                                            <i className="fa fa-skype" />
                                            <strong>Skype</strong>
                                        </a>
                                        <p>florinpop1705</p>
                                    </div>
                                </div> */}
                                <div className="col-sm-8 col-sm-offset-2 col-xs-12">
                                    <form
                                        name="contact"
                                        className="contact-form"
                                        method="post"
                                        action="/thanks"
                                        data-netlify="true"
                                        data-netlify-recaptcha="true"
                                        data-netlify-honeypot="bot-field"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                            <input
                                                type="hidden"
                                                name="form-name"
                                                value="contact"
                                            />
                                            <div
                                                className="col-sm-6 col-xs-12 form-group"
                                                hidden
                                            >
                                                <input
                                                    name="bot-field"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Bot Field"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-sm-6 col-xs-12 form-group">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Name"
                                                    name="name"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-sm-6 col-xs-12 form-group">
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    placeholder="Email"
                                                    name="email"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 form-group">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Subject"
                                                    name="subject"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 form-group">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Message"
                                                    name="message"
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 form-group">
                                                {RECAPTCHA_KEY ? (
                                                    <Recaptcha
                                                        ref="recaptcha"
                                                        sitekey={RECAPTCHA_KEY}
                                                        onChange={
                                                            this.handleRecaptcha
                                                        }
                                                    />
                                                ) : (
                                                    'Recaptcha'
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3 col-xs-12 form-group">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg"
                                                >
                                                    Send{' '}
                                                    <i className="fa fa-send" />
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    };
}

export default Contact;
